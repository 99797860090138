

import {AppEventBus, FileInput, Helper, Settings} from "@xnpmpackages/xcomponents/dist/index";


export default {
    name: "Params",
    methods: {

        changeTheme(theme) {
            AppEventBus.emit('theme-change-preset', theme);
        },

        async uploadMedoMessage($file) {

            if (!$file)
                return;

            this.$refs.uploadMedoMessageFileInput.removeFile();

            try {

                this.$xapi.showBlockModal('Загрузка сообщения...');

                let formData = new FormData();
                formData.append('action', 'uploadMedoMessageInbound');
                formData.append('messageZip', $file);

                await this.$xapi.postForm(
                    '/common-actions',
                    formData,
                );

                this.$xapi.xnotify('Сообщение добавлено в очередь на обработку');

            } finally {
                this.$xapi.hideBlockModal();
            }

        },
        async downloadSupport() {

            this.$xapi.showBlockModal('Выгрузка файла...')
            try {
                await Helper.methods.downloadFileFromUrl('/common-actions/get-support-zip');
            } finally {
                this.$xapi.hideBlockModal();
            }

        }
    },
    components: {
        Settings,
        FileInput
    }
}
